import { connect } from 'react-redux';
import { StateReducer } from '../../../reducers';
import { DocumentationForm } from './page';
import { DocumentDetail, Language, UserState } from '../../../model';
import { getTradeCompaniesAction } from '../actions/getCompanies';
import { getDocumentTypePrimariesAction } from '../actions/getDocumentTypePrimaries';
import { getDocumentTypeSubTypeAction } from '../actions/getDocumentTypeSubTypes';
import { getCountriesAction } from '../actions/getCountries';
import { getOriginalsAction } from '../actions/getOriginals';
import { saveDocumentAction } from '../actions/saveDocument';
import { getDocumentAction } from '../actions/getDocument';
import { Modules } from '../../../model/enums';
import { getBusinessAction } from '../actions/getBusiness';

const moduleId = Modules.Documentation;

const mapStateToProps = (state: StateReducer) => ({
	user: state.user,
	language: state.language,
	bodyType: state.bodyType,
	companies: state.tradeCompanies,
	currentUser: state.users,
	documentTypes: state.documentTypePrimaries,
	documentSubtypes: state.documentTypeSubTypes,
	countries: state.countries,
	originals: state.originals,
	document: state.document,
	business: state.businessNames,
});

const mapDispatchToProps = (dispatch: any) => ({
	getCompanies: (tradeId?: number) => dispatch(getTradeCompaniesAction(moduleId, tradeId)),
	getDocumentTypePrimaries: () => dispatch(getDocumentTypePrimariesAction()),
	getDocumentTypeSubTypes: () => dispatch(getDocumentTypeSubTypeAction()),
	getCountries: () => dispatch(getCountriesAction()),
	getOriginals: () => dispatch(getOriginalsAction()),
	saveDocument: (document: DocumentDetail, Files:File[], language: Language, currentUser: UserState, objetoTrazas: string) => dispatch(saveDocumentAction(document,Files, language, currentUser, objetoTrazas)),
	getDocument: (id: number) => dispatch(getDocumentAction(id)),
	getBusiness: () => dispatch(getBusinessAction()),
});

export const DocumentationFormContainer = connect(mapStateToProps, mapDispatchToProps)(DocumentationForm);
