import { MsalAuthProvider, LoginType } from 'react-aad-msal';

export class runtimeVariables {
    static _instance: runtimeVariables;

    	public REACT_APP_ENVIRONMENT;
		public REACT_APP_BUILD ;
		public REACT_APP_TENANTID ;
		public REACT_APP_CLIENTID;
		public REACT_APP_API;
		public REACT_APP_REDIRECT_URI;
		public REACT_APP_INSTRUMENTATION_KEY;
		public REACT_APP_SHAREPOINT;
		public REACT_APP_CLARITY;
        public config:any;
        public authenticationParameters: any;
        public authProvider: MsalAuthProvider;
        public options;
        public adminMaster;
        public admin;
        public ajAeropuertos;
        public ajAeropuertosH;
        public ajCintra;
        public ajCintraH;
        public ajConstruccion;
        public ajConstruccionH;
        public ajCorporacion;
        public ajCorporacionH;
        public tesoAeropuertos;
        public tesoCintra;
        public tesoConstruccion;
        public tesoCorporacion;
        public ajFeim;
        public ajFeimH;

    /**
     * The Singleton's constructor should always be private to prevent direct
     * construction calls with the `new` operator.
     */
    private constructor(url:string) { 
        if(url.indexOf("https://app-poderes-front-des-001.azurewebsites.net") !== -1){
            this.REACT_APP_ENVIRONMENT = "INT";
            this.REACT_APP_BUILD = "INT";
            this.REACT_APP_TENANTID = "d637cd85-571a-4bf6-8527-9369a16e1653";
            this.REACT_APP_CLIENTID = "4bd20f80-d4f9-442b-9376-64be83b2d051";
            this.REACT_APP_API = "https://app-poderes-back-des-002.azurewebsites.net";
            this.REACT_APP_REDIRECT_URI = "https://app-poderes-front-des-001.azurewebsites.net";
            this.REACT_APP_INSTRUMENTATION_KEY = "c0f1d039-59c5-4aef-ba22-9d175e812a0e";
            this.REACT_APP_SHAREPOINT ="https://ferclouddes.sharepoint.com";
            this.REACT_APP_CLARITY = "gy64ls6p9y";
            this.adminMaster = 'SG_BA0613_01_PP';
            this.admin = 'SG_BA0613_02_PP';
            this.ajAeropuertos = 'SG_BA0613_03_PP';
            this.ajAeropuertosH = 'SG_BA0613_04_PP';
            this.ajCintra = 'SG_BA0613_05_PP';
            this.ajCintraH = 'SG_BA0613_06_PP';
            this.ajConstruccion = 'SG_BA0613_07_PP';
            this.ajConstruccionH = 'SG_BA0613_08_PP';
            this.ajCorporacion = 'SG_BA0613_09_PP';
            this.ajCorporacionH = 'SG_BA0613_10_PP';
            this.tesoAeropuertos = 'SG_BA0613_11_PP';
            this.tesoCintra = 'SG_BA0613_12_PP';
            this.tesoConstruccion = 'SG_BA0613_13_PP';
            this.tesoCorporacion = 'SG_BA0613_14_PP';
            this.ajFeim = 'SG_BA0613_15_PP';
            this.ajFeimH = 'SG_BA0613_16_PP';
        }else if(url.indexOf("https://poderesyautorizadospre.ferrovial.com") !== -1){
            this.REACT_APP_ENVIRONMENT = "PRE";
            this.REACT_APP_BUILD = "PRE";
            this.REACT_APP_TENANTID = "a9a8e375-fac1-4ec2-820a-cfb6eb5cf01b";
            this.REACT_APP_CLIENTID = "b641bf7d-90d2-4036-b9b0-24a160ddb254";
            this.REACT_APP_API = "https://poderesyautorizadosbackpre.ferrovial.com";
            this.REACT_APP_REDIRECT_URI = "https://poderesyautorizadospre.ferrovial.com";
            this.REACT_APP_INSTRUMENTATION_KEY = "94b44163-acce-4f79-8ea7-bd7f967b509e";
            this.REACT_APP_SHAREPOINT ="https://ferrovial365.sharepoint.com";
            this.REACT_APP_CLARITY = "gz2w6jx1n9";
            this.adminMaster = 'SG_BA0613_01_PP';
            this.admin = 'SG_BA0613_02_PP';
            this.ajAeropuertos = 'SG_BA0613_03_PP';
            this.ajAeropuertosH = 'SG_BA0613_04_PP';
            this.ajCintra = 'SG_BA0613_05_PP';
            this.ajCintraH = 'SG_BA0613_06_PP';
            this.ajConstruccion = 'SG_BA0613_07_PP';
            this.ajConstruccionH = 'SG_BA0613_08_PP';
            this.ajCorporacion = 'SG_BA0613_09_PP';
            this.ajCorporacionH = 'SG_BA0613_10_PP';
            this.tesoAeropuertos = 'SG_BA0613_11_PP';
            this.tesoCintra = 'SG_BA0613_12_PP';
            this.tesoConstruccion = 'SG_BA0613_13_PP';
            this.tesoCorporacion = 'SG_BA0613_14_PP';
            this.ajFeim = 'SG_BA0613_15_PP';
            this.ajFeimH = 'SG_BA0613_16_PP';
        }else if(url.indexOf("https://poderesyautorizados.ferrovial.com") !== -1 || url.indexOf("https://asw-we-p-poderesfront-01.azurewebsites.net/") !== -1){
            this.REACT_APP_ENVIRONMENT = "PRO";
            this.REACT_APP_BUILD = "PRO";
            this.REACT_APP_TENANTID = "a9a8e375-fac1-4ec2-820a-cfb6eb5cf01b";
            this.REACT_APP_CLIENTID = "c09d383e-38e0-4e83-84a2-17d0548c5b33";
            this.REACT_APP_API = url.indexOf("https://poderesyautorizados.ferrovial.com") !== -1 ? "https://poderesyautorizadosback.ferrovial.com" : "https://asw-we-p-poderesback-01.azurewebsites.net/";
            this.REACT_APP_REDIRECT_URI = url;
            this.REACT_APP_INSTRUMENTATION_KEY = "0b8f0fb5-8f4d-4e21-87c0-6112f43557fa";
            this.REACT_APP_SHAREPOINT ="https://ferrovial365.sharepoint.com";
            this.REACT_APP_CLARITY = "gy2e5o6btd";
            this.adminMaster = 'SG_BA0613_01_P';
            this.admin = 'SG_BA0613_02_P';
            this.ajAeropuertos = 'SG_BA0613_03_P';
            this.ajAeropuertosH = 'SG_BA0613_04_P';
            this.ajCintra = 'SG_BA0613_05_P';
            this.ajCintraH = 'SG_BA0613_06_P';
            this.ajConstruccion = 'SG_BA0613_07_P';
            this.ajConstruccionH = 'SG_BA0613_08_P';
            this.ajCorporacion = 'SG_BA0613_09_P';
            this.ajCorporacionH = 'SG_BA0613_10_P';
            this.tesoAeropuertos = 'SG_BA0613_11_P';
            this.tesoCintra = 'SG_BA0613_12_P';
            this.tesoConstruccion = 'SG_BA0613_13_P';
            this.tesoCorporacion = 'SG_BA0613_14_P';
            this.ajFeim = 'SG_BA0613_15_P';
            this.ajFeimH = 'SG_BA0613_16_P';
        }else{
            throw new Error("Entorno no válido " + url);

        }

        this.config = {
            auth: {
                clientId: this.REACT_APP_CLIENTID,
                authority: `https://login.microsoftonline.com/${this.REACT_APP_TENANTID}`,
                redirectUri: this.REACT_APP_REDIRECT_URI,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: true,
            },
        };

        this.authenticationParameters = {
            scopes: ['user.read', `api://${this.REACT_APP_CLIENTID}/SPA`],
        };

        this.options =  {
            loginType: LoginType.Redirect,
            tokenRefreshUri: window.location.origin,
        };

        this.authProvider = new MsalAuthProvider(this.config, this.authenticationParameters, this.options);

    }

    /**
     * The static getter that controls access to the singleton instance.
     *
     * This implementation allows you to extend the Singleton class while
     * keeping just one instance of each subclass around.
     */
    public static instance(url:string): runtimeVariables {
        if (!runtimeVariables._instance) {
            runtimeVariables._instance = new runtimeVariables(url);
        }

        return runtimeVariables._instance;
    }

    /**
     * Finally, any singleton can define some business logic, which can be
     * executed on its instance.
     */
}