import { ComboBox, DatePicker, DayOfWeek, IComboBox, IComboBoxOption, Icon, IDatePickerStrings, MaskedTextField, TextField, TooltipHost } from 'office-ui-fabric-react';
import { MessageBarType } from 'office-ui-fabric-react/lib/components/MessageBar';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { RouteComponentProps } from 'react-router';
import 'react-tabs/style/react-tabs.scss';
import { Constants } from '../../../common/const/constants';
import { CustomSelect, ISelectOption } from '../../../common/customSelect/customSelect';
import { MessageBarComponent } from '../../../common/messageBar/messageBarComponent';
import { SearchPeople } from '../../../common/search/searchPeople';
import { SpinnerContainer } from '../../../common/spinner';
import { UnauthorizedView } from '../../../common/unauthorizedView/unauthorizedView';
import {
	BaseType,
	CompanySearch,
	createEmptyPowerRevocationFilter,
	DocumentFile,
	FileSp,
	KeyValue,
	Language,
	NotaryPeople,
	Person,
	Power,
	PowerRepresentativeDetail,
	PowerRevocationFilter,
	PowerSubject,
	SearchSelectModel,
	TradeCompany,
	UntranslatedBaseType,
	User,
	UserState
} from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import '../../../styles/pages/deeds.scss';
import { businessTypeEnum } from '../../../utils/enums/businessTypeEnum';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';
import { RepresentativeTabs } from './representativeTabs';
import { languages } from '../../../common/const/resources';
import { RepresentativeApi } from '../../../api/representatives';
import { PowerApi } from '../../../api/power';
import { runtimeVariables } from '../../../globalEnvVariables';

export interface IProps {
	power: PowerRepresentativeDetail;
	companies: TradeCompany[];
	notaryDocuments: UntranslatedBaseType[];
	peopleNames: Person[];
	notaryPeopleNames: NotaryPeople[];
	powers: BaseType[];
	powerTypeNames: BaseType[];
	language: Language;
	user: User;
	business: BaseType[];
	departments: BaseType[];
	powerExerciseNames: BaseType[];
	loading: boolean;
	powerSubjects: PowerSubject[];
	companiesSearch:CompanySearch[];
	lastBox: string;
	trazas: string;

	//TODO: Añadir al savePower el estado previo que debería ser this.props.power

	savePower: (power: Power, language: Language, currentUser: UserState) => Promise<number>;
	getPower: (id: number) => Promise<PowerRepresentativeDetail>;
	getCompanies: () => Promise<TradeCompany[]>;
	getNotaryDocuments: () => Promise<UntranslatedBaseType[]>;
	getPeopleNames: (filter: string) => Promise<Person[]>;
	getNotaryPeopleNames: (filter: string) => Promise<NotaryPeople[]>;
	getPowers: () => Promise<BaseType[]>;
	deleteFileSp: (fileSp: FileSp) => Promise<boolean>;
	getPowerTypes: () => Promise<BaseType[]>;
	getBusiness: (tardeId?: number) => Promise<BaseType[]>;
	getDepartments: () => Promise<BaseType[]>;
	getPowerExerciseNames: () => Promise<BaseType[]>;
	getPowerSubjects: (filter: PowerRevocationFilter) => Promise<PowerSubject[]>;
	getCompaniesSearch:(filter:string)=>Promise<CompanySearch[]>;
	getLastBox:() => Promise<string>;
}

export interface IState {
	power: Power;
	files: File[];
	initialFiles: DocumentFile[];
	errorMessages: string[];
	addressVisible: boolean;
	tradeId: number;
	cif: string;
	isReadOnly: boolean;
	isAuthorized: boolean;
	isEditMode: boolean;
	powerTypeId: number;
	powerRevocationFilter: PowerRevocationFilter;
	hasMaxError: boolean;
	uniqueCode: SearchSelectModel;
	uniqueCodes: KeyValue[];
	isAnyRepresentative: boolean;
	showDissolved: boolean;
	trazasDocumentos: string[];
	trazaCambioPowerType: string;
	companyName: string;
	negocio: string;
}

export class RepresentativeForm extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			power: {
				id: 0,
				companyId: 0,
				documentDate: new Date(),
				documents: [],
				notaryData: {
					id: 0,
					city: '',
					protocolNumber: '',
					notary: '',
					minutes: '',
					//notaryPeople: { id: 0, name: '', email: ''}
					notaryPeopleId: null,
					notaryPeopleName: ''
				},
				recordData: {
					id: 0,
					merchandRecord: '',
					volume: '',
					section: '',
					folio: '',
					sheet: '',
					inscription: '',
					inscriptionDate: null,
				},
				representatives: [],
				localization: '',
				box: '',
				subject: ''
			},
			files: [],
			initialFiles: [],
			errorMessages: [],
			addressVisible: false,
			tradeId: 0,
			cif: '',
			isReadOnly: false,
			isAuthorized: true,
			powerTypeId: 0,
			powerRevocationFilter: createEmptyPowerRevocationFilter(),
			isEditMode: false,
			hasMaxError: false,
			uniqueCode: null,
			uniqueCodes: [],
			isAnyRepresentative: false,
			showDissolved: true,
			trazasDocumentos:[],
			trazaCambioPowerType: "",
			companyName: "",
			negocio: ""
		};
	}

	public componentDidMount() {
		window.scrollTo(0, 0);
		if(window.location.href.includes("new")){
			this.setState(({showDissolved: false}))
		}

		if (this.props.match.params['id'] != null) {
			if (this.props.match.path.includes('view')) {
				this.setState({ isReadOnly: true });
			}
			this.setState({ isEditMode: true });
			this.props.getPower(this.props.match.params['id']);
		}

		this.props.getCompanies();
		this.props.getPeopleNames();
		this.props.getNotaryPeopleNames();
		this.props.getPowers();
		this.props.getPowerTypes();
		this.props.getBusiness();
		this.props.getDepartments();
		this.props.getPowerExerciseNames();
		this.props.getLastBox();
	}

	public componentWillReceiveProps(nextProps) {
		if (nextProps.power !== this.props.power) {
			let filter = { ...this.state.powerRevocationFilter };

			let company: TradeCompany = this.props.companies.find((x) => x.id === parseInt(nextProps.power.companyId));

			let tradeId = company?.tradeId;
			let cif = company?.cif;
			let userRole = this.props.currentUser.currentUser?.roles[0];
			let userBusinessId = utils.getBusinessNewPowerByRole(userRole);
			let isAuthorized = true;
			if (userBusinessId !== 0 && userBusinessId !== tradeId && !this.state.isReadOnly) {
				isAuthorized = false;
			}
			let representative = nextProps.power.representatives[0];
			let powerTypeId = representative?.powerTypeId;
			filter.companyId = company?.id;
			filter.powerTypeId = powerTypeId;
			this.setState({
				power: { ...nextProps.power },
				tradeId: tradeId,
				initialFiles: nextProps.power.documents,
				cif: cif,
				isAuthorized: isAuthorized,
				powerTypeId: powerTypeId,
				powerRevocationFilter: filter,
				uniqueCode: company ? { label: company.interlocutor, value: company.id } : null,
				isAnyRepresentative: true,
			});

		}
	}

	public componentDidUpdate(prevProps, prevState) {
		if (this.props.companies && this.props.companies.length > 0 && prevProps.companies !== this.props.companies) {
			let uniqueCode = this.state.uniqueCode;

			let uniqueCodes: KeyValue[] = this.props.companies.map((company: TradeCompany) => {
				return { Key: company.id, Value: company.interlocutor };
			});

			if (this.props.power?.companyId && this.props.match.params['id'] && !uniqueCode) {
				let company: TradeCompany = this.props.companies.find((x) => x.id === parseInt(this.props.power.companyId));
				if (company) {
					uniqueCode = { label: company.interlocutor, value: company.id };

					//Se agregan estas líneas para solucionar problemas en el caso de que en componentWillReceiveProps
					//las sociedades no se hayan cargado todavía y el companyId no se haya podido obtener
					//lo que está provocando que el desplegable de poderes a revocar no se muestre
					let filter = { ...this.state.powerRevocationFilter };
					filter.companyId=company?.id;
					this.setState({tradeId: company?.tradeId, cif: company?.cif, powerRevocationFilter: filter});
				}
			}

			this.setState({
				uniqueCodes,
				uniqueCode,
			});
		}
	}

	private readonly getSelectedCompany = () : ISelectOption[] => {
		let selectedCompanies: ISelectOption[] = [];
		if (this.props.companies && this.props.companies.length > 0 && this.state.power.companyId){
			let company: TradeCompany = this.props.companies.find((x) => x.id === this.state.power.companyId);
			if(company){
				selectedCompanies.push( {value:company.id,label:company.name, disolutionDate: null, liquidationDate: null});
			}
		}
		return selectedCompanies;
	}

	private readonly loadCodeOptions = (inputValue, callback) => {
		if (inputValue.length >= 2) {
			setTimeout(() => {
				callback(this.filterCodes(inputValue));
			}, 1000);
		}
	};

	private readonly filterCodes = (inputValue: string): SearchSelectModel[] => {
		let options = this.state.uniqueCodes.filter((i: KeyValue) => i.Value.toLowerCase().includes(inputValue.toLowerCase()));
		return options.map((option) => {
			return { value: option.Key, label: option.Value };
		});
	};

	public render() {

		let powerTypes: BaseType[] = this.props.powerTypeNames ? this.props.powerTypeNames : [];
		let language = utils.getResourcesFile(this.props.language);
		let companiesCombo: IComboBoxOption[] = [];

		if(this.props.companies){
			this.props.companies.forEach((item) => {
				companiesCombo.push({ 
					key: item.id, 
					text: item.name,
					id: item.fechaDisolucion ? new Date(item.fechaDisolucion).toLocaleDateString() : null,
					title: item.fechaLiquidacion ? new Date(item.fechaLiquidacion).toLocaleDateString() : null, });
			});			
		}

		let isReadOnly = this.state.isReadOnly;
		let tabs = (
			<RepresentativeTabs
				power={this.state.power}
				updateFormRepresentative={this.updateFormPower}
				peopleNames={this.props.peopleNames}
				notaryPeopleNames={this.props.notaryPeopleNames}
				powerTypes={powerTypes}
				language={this.props.language}
				getPeopleNames={this.props.getPeopleNames}
				getNotaryPeopleNames={this.props.getNotaryPeopleNames}
				departments={this.props.departments}
				powerExerciseNames={this.props.powerExerciseNames}
				isReadOnly={this.state.isReadOnly}
				powerTypeId={this.state.powerTypeId}
				getPowerSubjects={this.getPowerSubjects}
				powerSubjects={this.props.powerSubjects}
				powerRevocationFilter={this.state.powerRevocationFilter}
				getCompaniesSearch={this.props.getCompaniesSearch}
				companiesSearch={this.props.companiesSearch}
				trazas={this.props.trazas}
			/>
		);

		let business: IComboBoxOption[] = [];
		if (this.props.business) {
			this.props.business.forEach((item) => {
				business.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
		}
		let validationErrors = this.state.errorMessages?.map((item: string) => {
			return <MessageBarComponent Message={item} Type={MessageBarType.error} MessageVisible={true} close={null} />;
		});
		let documents: DocumentFile[] = this.state.initialFiles ? this.state.initialFiles : [];
		let calendarStrings: IDatePickerStrings = language['calendar'];
		let title = isReadOnly ? language['representatives']['form']['text-view'] : language['representatives']['form']['text-edit'];
		let departments: IComboBoxOption[] = [];
		this.props.departments.forEach((item) => {
			if (this.props.language.name.includes(ActiveLanguage.English)) {
				departments.push({ key: item.id, text: item.descriptionEnglish });
			} else {
				departments.push({ key: item.id, text: item.descriptionSpanish });
			}
		});
		let powerTypesCombo: IComboBoxOption[] = [];
		//1 = General, 2 = Especial, 3 = Financiero, 5 = Pleitos
		if(this.state.powerTypeId == 5 || this.state.powerTypeId == 0){
			powerTypes.forEach((item) => {
				powerTypesCombo.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
				
			});
		}else if(this.state.powerTypeId == 3 || this.state.powerTypeId == 2 || this.state.powerTypeId == 1){
			powerTypes.forEach((item) => {
				if(item.id == 1 || item.id == 2 || item.id == 3){
					powerTypesCombo.push({
						key: item.id,
						text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
					});
				}
			});
		}else{
			powerTypes.forEach((item)=>{
				powerTypesCombo.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			})
		}


		const maskFormat: { [key: string]: RegExp } = {
			'X': /[a-zA-Z]/,
			'n': /[0-9]/,
		};

		// const onRenderOption = (option: any): JSX.Element => {
		// 	if (option.itemType == DropdownMenuItemType.Header) {
		// 		if(option.key == 0){
		// 			return (
		// 				<Toggle 
		// 				inlineLabel 
		// 				label={language['SocietiesCombo']['showDissolved']} 
		// 				checked = {this.state.showDissolved}
		// 				onChange={(event, checked)=>{this.setState({showDissolved: checked})}} />
		// 			);
		// 		}
		// 		else{	
		// 			return (
		// 				<table>
		// 					<tbody>
		// 						<tr>
		// 							<td style={{ width: 15, fontSize: 14, padding: 5 }}></td>
		// 							<td style={{ width: 200, fontSize: 14, padding: 0 }}>{option.key}</td>
		// 							<td style={{ width: 100, fontSize: 14, padding: 0 }}>{option.text}</td>
		// 							<td style={{ width: 100, fontSize: 14, padding: '0px 10px' }}>{option.title}</td>

		// 						</tr>
		// 					</tbody>
		// 				</table>
		// 			);
		// 		}
		// 	} else {
		// 		return (
		// 			<table>
		// 				<tbody>
		// 					<tr>
		// 						<td style={{ width: 200, fontSize: 14, padding: 5 }}>{option.text}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.title}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.id}</td>
		// 					</tr>
		// 				</tbody>
		// 			</table>
		// 		);
		// 	}
		// };
		
		// let sortedCompanies = companiesCombo.sort((a, b) => a.text.localeCompare(b.text))
		// sortedCompanies.unshift({ key: language['SocietiesCombo']['name'], text: language['SocietiesCombo']['settlementDate'], title:language['SocietiesCombo']['dissolutionDate'], itemType: DropdownMenuItemType.Header });
		// sortedCompanies.unshift({ key: 0, text: "", itemType: DropdownMenuItemType.Header });	
		
		

		let sortedCompanies = companiesCombo.sort((a, b) => a.text.localeCompare(b.text))
		//sortedCompanies.unshift({ key: language['SocietiesCombo']['name'], text: language['SocietiesCombo']['settlementDate'], title:language['SocietiesCombo']['dissolutionDate'], itemType: DropdownMenuItemType.Header });
		//sortedCompanies.unshift({ key: 0, text: "", itemType: DropdownMenuItemType.Header });	
		
		//let options: ISelectOption[] = [];

		let filteredCompanies= (this.state.showDissolved) ? sortedCompanies : sortedCompanies.filter(x=> (x.title == null && x.id == null)|| x.title === language['SocietiesCombo']['dissolutionDate'])
		let options: ISelectOption[] = filteredCompanies.map(company => {
			return {
				value:company.key.toString(),
				label:company.text, 
				disolutionDate:company.id, 
				liquidationDate: company.title
			};
		  });
		let selectedCompanies=this.getSelectedCompany();	

		// console.log("handleReactSelectCompanyChange RENDER this.state.power.companyId:" + this.state.power.companyId)
		// let selectedCompanies: ISelectOption[] = [];
		// let company: TradeCompany = this.props.companies.find((x) => x.id === this.state.power.companyId);
		// if(company){
		// 	console.log("handleReactSelectCompanyChange RENDER company.id:" + company.id)
		// 	selectedCompanies.push( {value:company.id,label:company.name, disolutionDate: null, liquidationDate: null});
		// }
	  
		const calloutProps = { gapSpace: 0 };

		return (
			<>
				{!this.state.isAuthorized && <UnauthorizedView language={this.props.language} />}
				{this.state.isAuthorized && (
					<>
						<SpinnerContainer message='Cargando...' />
						<div className='main-container__pageTitle'>
							<h1>
								{this.props.match.params['id'] == null ? language['representatives']['form']['text-add'] : title}
								{language['representatives']['form']['title']}
							</h1>
						</div>
						<div className='ms-Grid'>
							<div className='ms-Grid-row flex-option'>
								<div className='ms-Grid-col ms-xl9'>
									<p className='subtitle'>{language['representatives']['form']['general-data']}</p>
								</div>
								<div className='ms-Grid-col ms-xl3'>
									<p className='subtitle'>{language['representatives']['form']['documentation']}</p>
								</div>
							</div>
							<div className='ms-Grid-row flex-option new_deed'>
								<div className='ms-Grid-col ms-xl3 content_edit_block_filter'>
									<p>{language['representatives']['form']['fields']['trade']['title']}</p>
									<ComboBox
										options={business}
										placeholder={'-'}
										selectedKey={this.state.tradeId}
										disabled={isReadOnly || this.state.isAnyRepresentative}
										useComboBoxAsMenuWidth
										onChange={this.handleBusinessChange}
									/>
									 <p>{language['representatives']['form']['fields']['society']['title']}</p>
									
									{/* <ComboBox
										autoComplete='on'
										allowFreeform={true}
										options={this.state.showDissolved ? sortedCompanies : sortedCompanies.filter(x=> (x.title == null && x.id == null)|| x.title == language['SocietiesCombo']['dissolutionDate'])}
										placeholder={language['representatives']['form']['fields']['society']['placeholder']}
										onChange={this.handleCompanyChange}
										onRenderOption={onRenderOption}
										selectedKey={this.state.power.companyId}
										disabled={(this.props.match.params['id'] != null && isReadOnly)}
									/> */}

									<CustomSelect
										options={options}
										handleCustomSelectChange={this.handleReactSelectCompanyChange}
										showDissolved={this.state.showDissolved}
										handleShowDissolvedChange={(event, checked)=>{this.setState({showDissolved: checked})}}
										language={language}
										showDissolutionDate={false}
										selectedValues={selectedCompanies}
										isMultiSelection={false}
										isDisabled={(this.props.match.params['id'] != null && isReadOnly)}
										placeholder={language['representatives']['form']['fields']['society']['placeholder']}
									/>

									<p>{language['representatives']['form']['fields']['uniqueCode']['title']}</p>
									<SearchPeople
										title={''}
										placeholder={language['representatives']['form']['fields']['uniqueCode']['placeholder']}
										language={this.props.language}
										selectedOption={this.state.uniqueCode}
										loadOptions={this.loadCodeOptions}
										handleNameChange={this.handleUniqueCodeChange}
										getUsers={this.props.getPeopleNames}
										disabled={isReadOnly}
										onClearValue={this.onClearSearchPeople}
									/>
									<p>{language['representatives']['form']['fields']['nif']['title']}</p>
									<TextField type='text' value={this.state.cif} disabled={true} placeholder={'-'} />
								</div>
								<div className='ms-Grid-col ms-xl3 content_edit_block_filter'>
									<p>{language['representatives']['newform']['powerType']['title'] + '*'}</p>
									
									<ComboBox
										allowFreeform
										autoComplete='on'
										options={powerTypesCombo}
										placeholder={language['representatives']['newform']['powerType']['placeholder']}
										onChange={this.handlePowerTypeChange}
										selectedKey={this.state.powerTypeId}
										useComboBoxAsMenuWidth
										disabled={isReadOnly || this.state.powerTypeId == 5}
									/>
									<p>{language['representatives']['form']['fields']['protocol']['title']}</p>
									<TextField
										type='text'
										value = {utils.isNullOrEmpty(this.state.power.notaryData?.protocolNumber) ? '' : this.state.power.notaryData?.protocolNumber}
										onChange={this.handleProtocolIdChange}
										onBlur={this.handleProtocolIdBlur}
										disabled={isReadOnly}
										maxLength={5}
									/>
									<p>{language['representatives']['form']['fields']['document-date']['title']}</p>
									<DatePicker
										formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
										className={''}
										firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
										allowTextInput={true}
										parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
										placeholder={language['generic']['form']['fields']['date']['from']}
										ariaLabel={language['generic']['form']['fields']['date']['from']}
										strings={calendarStrings}
										value={utils.getParsedDate(this.state.power.documentDate)}
										onSelectDate={this.handleDocumentDateChange}
										disabled={isReadOnly}
									/>
								</div>
								<div className='ms-Grid-col ms-xl3 content_edit_block_filter'>
									<p>{language['representatives']['form']['fields']['subject']['title']}</p>
									<TextField
										type='text'
										value = {utils.isNullOrEmpty(this.state.power.subject) ? '' : this.state.power.subject}
										onChange={this.handleSubjectChange}
										onBlur={this.handleSubjectBlur}
										disabled={isReadOnly}
										maxLength={1000}
									/>
									<p>{language['representatives']['form']['fields']['shelves']['title']}</p>
									<TooltipHost
									        content={this.props.lastBox}
											calloutProps={calloutProps}>
										<MaskedTextField
											mask="XXnn/nn"
											maskFormat={maskFormat}										
											type='text'
											value = {utils.isNullOrEmpty(this.state.power.box) ? '' : this.state.power.box}
											onChange={this.handleShelfChange}
											onBlur={this.handleShelfBlur}
											disabled={isReadOnly}
											maxLength={250}
										/>      
									</TooltipHost>
									<p>{language['representatives']['form']['fields']['location']['title']}</p>
									<TextField
										type='text'
										value = {utils.isNullOrEmpty(this.state.power.localization) ? '' : this.state.power.localization}
										onChange={this.handleLocationChange}
										onBlur={this.handleLocationBlur}
										disabled={isReadOnly}
										maxLength={255}
									/>
								</div>
								<div className='ms-Grid-col ms-xl3 content_edit_block_filter'>
									<span style={{ display: isReadOnly ? 'none' : '' }}>
										<p>{language['representatives']['form']['fields']['documents']['title']}</p>
										<Dropzone className='dropzone' onDrop={this.onDrop}>
											<i className='ms-Icon ms-Icon--CloudUpload' aria-hidden='true'></i>
											<p className='dropzone-title'>{language['generic']['form']['dropzone']['title']}</p>
											<p className='dropzone-maxsize'>{language['generic']['form']['dropzone']['max-size']}</p>
											<span className='custom-file-upload'>
												<input type='file' onChange={this.uploadFile} />
												<Icon iconName='CloudUpload'></Icon>{' '}
												{language['generic']['form']['uploadDocument']['title']}
											</span>
										</Dropzone>
									</span>
									<ul className='ms-Grid-col ul-documents'>
										{this.state.files.length == 0 ?(
												<>
												<MessageBarComponent 
													ClassName='fixMsgMaxError'
													Message={language['generic']['form']['noDocs']['title']}
													Type={MessageBarType.warning}
													MessageVisible={true}
													close={null}
												/>
												</>
											): <></>}
										{this.state.hasMaxError ? (
											<MessageBarComponent
												ClassName='fixMsgMaxError'
												Message={language['generic']['form']['dropzone']['max-size']}
												Type={MessageBarType.error}
												MessageVisible={true}
												close={null}
											/>
										) : (
											this.state.files.map((f) => (
												<li key={f.name}>
													{f.name}
													<span className='delete-files-icon' onClick={() => this.handleDeselect(f)}>
														&times;
													</span>
												</li>
											))
										)}
										{documents.map((item) => (
											<li key={item.fileName}>
												{/* <a
													href={runtimeVariables._instance.REACT_APP_SHAREPOINT + item.documentLink}
													target='_blank'
													rel='noopener noreferrer'
												>
													{item.fileName}
												</a> */}
												<span
													/*onClick={() => window.open(runtimeVariables._instance.REACT_APP_SHAREPOINT + item.documentLink, "_blank")}*/
													onClick={() => {
														const urltemp = runtimeVariables._instance.REACT_APP_SHAREPOINT + item.documentLink;
														const url = urltemp.replace(/ /g, "%20");
														if (utils.isURLValid(url)){
															window.open(url, "_blank")}
															//else{console.log(url)}
																	}
															}
													className='url-style'
												>
													{item.fileName}
												</span>
												<span
													className='delete-files-icon'
													onClick={() => this.handleDeleteFileSp(item)}
													style={{ display: isReadOnly ? 'none' : '' }}
												>
													&times;
												</span>
											</li>
										))}
									</ul>
								</div>
							</div>
							{validationErrors}
							{tabs}
							<div className='ms-Grid-row'>
								<hr />
								<button
									type='button'
									name='button'
									className={`button-primary ${!this.props.loading ? '' : 'isDisabled'} `}
									style={{ display: isReadOnly ? 'none' : '' }}
									onClick={this.handleSavePower}
								>
									{language['representatives']['form']['save-button']}
								</button>
								<button type='button' name='button' className={`button-cancel`} onClick={this.handleCancel}>
									{language['representatives']['form']['cancel-button']}
								</button>
							</div>
						</div>
					</>
				)}
			</>
		);
	}

	private readonly handlePowerTypeChange = (event, value): void => {
		let powerTypeId = value?.key;

		let filter = { ...this.state.powerRevocationFilter };
		filter.powerTypeId = powerTypeId;

		this.getPowerSubjects(filter);

		let power = {...this.state.power};

		power.representatives.forEach((representative)=>{
			representative.powerTypeId = powerTypeId;
		})


		let trazaPowerType = "";
		if(powerTypeId !== 0){
			switch(powerTypeId){
				case 1:
					trazaPowerType = "Se ha cambiado el tipo de poder a General"
					break;
				case 2:
					trazaPowerType = "Se ha cambiado el tipo de poder a Especial"
					break;
				case 3:
					trazaPowerType = "Se ha cambiado el tipo de poder a Financiero"
					break;
				case 5:
					trazaPowerType = "Se ha cambiado el tipo de poder a Pleitos"
			}
		}
		
		this.setState({ power: power , powerTypeId: powerTypeId, powerRevocationFilter: filter, trazaCambioPowerType: trazaPowerType });
	};

	public readonly handleCancel = () => {
		this.props.history.goBack();
	};

	private getTradeIdRole(): number {
		switch (this.props.currentUser.currentUser?.roles.sort()[0]) {
			case userRoles.ajAeropuertos:
			case userRoles.ajAeropuertosH:
				return businessTypeEnum.aeropuertos;
			case userRoles.ajCintra:
			case userRoles.ajCintraH:
				return businessTypeEnum.cintra;
			case userRoles.ajConstruccion:
			case userRoles.ajConstruccionH:
				return businessTypeEnum.construccion;
			case userRoles.ajCorporacion:
			case userRoles.ajCorporacionH:
				return businessTypeEnum.corporacion;
			case userRoles.ajFeim:
			case userRoles.ajFeimH:
				return businessTypeEnum.feim;
			case userRoles.admin:
				return 0;
		}
	}

	private validateForm(power: Power): boolean {
		let errorMessages: string[] = [];
		let language = utils.getResourcesFile(this.props.language);

		//Se comenta para que no sea obligatorio la subida de documentos
		//let existDocuments = power.documents?.length > 0;
		let hasDocumentDate = power.documentDate !== null;
		let hasCompany = power.companyId > 0;
		let hasRepresentatives = power.representatives?.length > 0;

		let isPowerValid = hasCompany && hasDocumentDate /*&& existDocuments*/ && hasRepresentatives;
		if (!hasCompany) {
			errorMessages.push(language['generic']['messages']['required-society']);
		}
		if (!hasDocumentDate) {
			errorMessages.push(language['generic']['messages']['required-document-date']);
		}
		// if (!existDocuments) {
		// 	errorMessages.push(language['generic']['messages']['required-document']);
		// }
		if (!hasRepresentatives) {
			errorMessages.push(language['generic']['messages']['required-representative']);
		}
		if (!isPowerValid) {
			this.setState({ errorMessages });
		}
		return isPowerValid;
	}

	private readonly updateFormPower = (power: Power) => {
		if (power.representatives.length > 0) {
			this.setState({
				isAnyRepresentative: true,
			});
		} else {
			this.setState({
				isAnyRepresentative: false,
			});
		}
		this.setState({ power: power });
	};

	private readonly handleCompanyChange = (event: React.FormEvent<IComboBox>, option: IComboBoxOption): void => {
		let power = { ...this.state.power };
		let filter = { ...this.state.powerRevocationFilter };

		
		power.companyId = option?.key as number;
		filter.companyId = power.companyId;
		let company: TradeCompany = this.props.companies.find((x: TradeCompany) => x.id === (option?.key as number));

		let tradeId = company?.tradeId;
		let cif = company?.cif;
		this.getPowerSubjects(filter);
		this.props.getDepartments(tradeId);
		this.setState({
			power: power,
			tradeId: tradeId,
			cif: cif,
			powerRevocationFilter: filter,
			uniqueCode: company ? { value: company.id, label: company.interlocutor } : null,
		});
	};

	private readonly handleReactSelectCompanyChange = (selectedOption) => {

		let power = { ...this.state.power };
		let filter = { ...this.state.powerRevocationFilter };

		let company: TradeCompany = null;

		if(selectedOption!==null && selectedOption.value !== null){

			//TODO: confirmar por qué el operador estricto no consigo que funcione
			debugger;
			company = this.props.companies.find((x: TradeCompany) => x.id === (Number(selectedOption?.value)));

		}

		power.companyId = company?.id;
		filter.companyId = power.companyId;
		let tradeId = company?.tradeId;
		let cif = company?.cif;
		this.getPowerSubjects(filter);
		this.props.getDepartments(tradeId);
		this.setState({
			power: power,
			tradeId: tradeId,
			cif: cif,
			powerRevocationFilter: filter,
			uniqueCode: company ? { value: company.id, label: company.interlocutor } : null,
			companyName: company ? company.name : null
		});

	};

	public readonly handleDocumentDateChange = (event): void => {
		let power = { ...this.state.power };
		power.documentDate = event;
		this.setState({ power: power });
	};
	public readonly handleBusinessChange = (event, value): void => {
		let power = { ...this.state.power };
		let filter = { ...this.state.powerRevocationFilter };
		power.companyId = 0;
		filter.companyId = power.companyId;
		let tradeId = value?.key;
		this.props.getCompanies(tradeId);
		this.setState({ tradeId: tradeId, power: power, powerRevocationFilter: filter, cif: '', uniqueCode: null, negocio: value.text });
	};

	private readonly onClearSearchPeople = (): void => {
		let uniqueCode = { ...this.state.uniqueCode };
		uniqueCode.value=null;
		uniqueCode.label=null;
		this.setState({ uniqueCode: uniqueCode });
	};

	private readonly handleUniqueCodeChange = (uniqueCode: SearchSelectModel) => {
		let power = { ...this.state.power };
		let filter = { ...this.state.powerRevocationFilter };

		power.companyId = +uniqueCode.value;
		filter.companyId = power.companyId;
		let company: TradeCompany = this.props.companies.find((x: TradeCompany) => x.id === power.companyId);
		let tradeId = company?.tradeId;
		let cif = company?.cif;
		this.getPowerSubjects(filter);
		this.props.getDepartments(tradeId);
		this.setState({
			power: power,
			tradeId: tradeId,
			cif: cif,
			powerRevocationFilter: filter,
			uniqueCode: uniqueCode,
		});
	};

	private readonly handleProtocolIdChange = (event) => {
		let power = { ...this.state.power };
		power.notaryData.protocolNumber = event.target.value;
		this.setState({ power });
	};
	private readonly handleProtocolIdBlur = (event) => {
		let power = { ...this.state.power };
		power.notaryData.protocolNumber = event.target.value.toUpperCase();
		this.setState({ power });
	};

	private readonly handleSubjectChange = (event): void => {
		let power = { ...this.state.power };
		power.subject = event.target.value;
		this.setState({ power: power });
	};
	private readonly handleSubjectBlur = (event) => {
		let power = { ...this.state.power };
		power.subject = event.target.value.toUpperCase();
		this.setState({ power });
	};

	public readonly handleShelfChange = (event): void => {
		let power = { ...this.state.power };
		power.box = event.target.value;
		this.setState({ power: power });
	};
	private readonly handleShelfBlur = (event) => {
		let power = { ...this.state.power };
		power.box = event.target.value.toUpperCase();
		this.setState({ power });
	};

	public readonly handleLocationChange = (event): void => {
		let power = { ...this.state.power };
		power.localization = event.target.value;
		this.setState({ power: power });
	};
	private readonly handleLocationBlur = (event) => {
		let power = { ...this.state.power };
		power.localization = event.target.value.toUpperCase();
		this.setState({ power });
	};

	private readonly handleSavePower = (): void => {

		let power = { ...this.state.power };
		if (power.representatives === null) {
			power.representatives = [];
		}
		// else{
		// 	power.representatives.forEach((representative)=>{
		// 		representative.powerTypeId = this.state.powerTypeId;
		// 		if(this.state.powerTypeId == 1){
		// 			representative.powerType = {id: this.state.powerTypeId, descriptionEnglish: "General", descriptionSpanish: "General"}
		// 		}else if(this.state.powerTypeId == 2){
		// 			representative.powerType = {id: this.state.powerTypeId, descriptionEnglish: "Special", descriptionSpanish: "Especial"}
		// 		}else if(this.state.powerTypeId == 3){
		// 			representative.powerType = {id: this.state.powerTypeId, descriptionEnglish: "Financial", descriptionSpanish: "Financiero"}				
		// 		}else{
		// 			representative.powerType = {id: this.state.powerTypeId, descriptionEnglish: "", descriptionSpanish: "Pleitos"}
		// 		}
		// 	});
	
		// }	
		if (this.validateForm(power)) {
			if(this.props.power !== this.state.power){
				if(power.id !== 0){
					let trazas:string[] = []
					let cambiosPoderGeneral: any = utils.getChangedFields(this.props.power, power)
					let cambiosNotaryData: any = utils.getChangedFields(this.props.power.notaryData, power.notaryData);
					let cambiosRecordData: any = utils.getChangedFields(this.props.power.recordData, power.recordData);

					if(Object.keys(cambiosNotaryData).length > 0 ){
						cambiosPoderGeneral.DatosNotariales = utils.objectToString(cambiosNotaryData);
					}

					if(Object.keys(cambiosRecordData).length > 0){
						cambiosPoderGeneral.DatosRegistrales = utils.objectToString(cambiosRecordData);
					}

					if(this.props.power.notaryData.protocolNumber !== power.notaryData.protocolNumber){
						cambiosPoderGeneral.NumeroProtocolo =  power.notaryData.protocolNumber;
					}
					if(this.props.power.documentDate !== power.documentDate){
						cambiosPoderGeneral.FechaDocumento = utils.formatDate(power.documentDate)
					}

					power.representatives.forEach((representative)=>{
						if(representative.trazas){
							trazas.push(representative.trazas)
						}
						//hemos de borrar la propiedad trazas porque el back no espera que se las mandemos
						//pero las necesitamos para poder construir el objeto de trazas de SOX
						delete representative.trazas;
					})

					const RepresentativeNamesNew = power.representatives.map((representative)=> representative.id)
					const RepresentativeNamesPrev = this.props.power.representatives.map((representative)=> representative.id)
					const ApoderadosBorrados = utils.findRemovedValuesFromArrays(RepresentativeNamesPrev, RepresentativeNamesNew);
					if(ApoderadosBorrados.length > 0){
							trazas.push(`Se han borrado los apoderados con id: ${ApoderadosBorrados}`)
					}

					if(trazas.length> 0){
							cambiosPoderGeneral.Apoderados = trazas;

					}

					if(this.state.trazasDocumentos.length > 0){
						cambiosPoderGeneral.documentos = this.state.trazasDocumentos;
						cambiosPoderGeneral.documentos.forEach((documento)=>{
							documento = documento.replace(",","\n");
						})
					}

					if(cambiosPoderGeneral.companyId){
						delete cambiosPoderGeneral.companyId;
						cambiosPoderGeneral.Compania = this.state.companyName;
					}

					if(this.state.trazaCambioPowerType !== ""){
						cambiosPoderGeneral.TipoPoder = this.state.trazaCambioPowerType;
					}


					
					let ObjetoEnviarTrazas = `${utils.objectToString(cambiosPoderGeneral)}`

					ObjetoEnviarTrazas.replace(",","\n");


					this.props.savePower(power, this.props.language,this.props.currentUser, ObjetoEnviarTrazas)
				}else{
					let trazas:string[] = []
					power.representatives.forEach((representative)=>{
						if(representative.trazas){
							trazas.push(representative.trazas)
						}
						//hemos de borrar la propiedad trazas porque el back no espera que se las mandemos
						//pero las necesitamos para poder construir el objeto de trazas de SOX
						delete representative.trazas;
					})

					let ObjetoEnviarTrazas: any = {
						Sociedad: this.state.companyName,
						TipoPoder: this.state.powerTypeId,
						Asunto: power.subject,
						FechaDocumento: utils.formatDate(power.documentDate),
						Apoderados: trazas,
						Negocio: this.state.negocio
					}

					if(this.state.trazasDocumentos.length > 0){
						ObjetoEnviarTrazas.Documentos = this.state.trazasDocumentos
					}

					if(power.notaryData.notaryPeopleName){
						let objetoNotaria: any = {}
						objetoNotaria.Notario = power.notaryData.notaryPeopleName
						objetoNotaria.Acta = power.notaryData.notary
						objetoNotaria.Pais = power.notaryData.city

						ObjetoEnviarTrazas.DatosNotariales = objetoNotaria
					}

					if(power.recordData){
						let objetoRegistro: any = {}
						objetoRegistro.RegistroMercantil = power.recordData.merchandRecord
						objetoRegistro.Folio = power.recordData.folio
						objetoRegistro.Seccion = power.recordData.section
						objetoRegistro.Inscripcion = power.recordData.inscription
						objetoRegistro.FechaInscripcion = power.recordData.inscriptionDate
						objetoRegistro.Hoja = power.recordData.sheet
						objetoRegistro.Tomo = power.recordData.volume

					}
					ObjetoEnviarTrazas = utils.objectToString(ObjetoEnviarTrazas);

					this.props.savePower(power, this.props.language,this.props.currentUser, ObjetoEnviarTrazas)
				}
			}
		}
	};

	public readonly onDrop = (files: File[]) => {
		if (files?.length === 1) {
			let documentsFiles: DocumentFile[] = [];
			let hasMaxError: boolean = false;
			files.forEach((file) => {
				if (file.size > Constants.MinSizeFile && file.size < Constants.MaxSizeFile) {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					let document: DocumentFile = {
						fileName: file.name,
						contents: '',
						deedMetadata: null,
						recordMetadata: null,
						corporateLitigationMetadata: null,
						powerMetadata: null,
						documentationMetadata: null,
					};
					reader.onloadend = function () {
						document.contents = (reader.result as string).split(',').pop();
					};
					documentsFiles.push(document);
					let trazasDocumentos = [...this.state.trazasDocumentos];
					trazasDocumentos.push(`Se ha añadido el documento con el nombre: ${document.fileName}`)
					this.setState({trazasDocumentos: trazasDocumentos})
				} else {
					hasMaxError = true;
				}
			});

			let state = { ...this.state };
			if (state.power.documents !== null && state.power.documents.length === 0 && !hasMaxError) {
				state.power.documents = state.power.documents.concat(documentsFiles);
			}
			if (state.files != null && state.files.length < 1 && !hasMaxError) {
				state.files = state.files.concat(files);
				this.setState(state);
			}
			this.setState({ hasMaxError });
		}
	};

	public readonly uploadFile = (event: any) => {

		let hasMaxError: boolean = false;
		let documentsFiles: DocumentFile[] = [];
		let file = event.target.files[0];

		if (file != null && file.size > Constants.MinSizeFile && file.size < Constants.MaxSizeFile) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			let document: DocumentFile = {
				fileName: file.name,
				contents: '',
				deedMetadata: null,
				recordMetadata: null,
				corporateLitigationMetadata: null,
				powerMetadata: null,
				documentationMetadata: null,
			};
			reader.onloadend = function () {
				document.contents = (reader.result as string).split(',').pop();
			};
			documentsFiles.push(document);
		} else {
			hasMaxError = true;
		}
		let state = { ...this.state };
		if (state.power.documents !== null && state.power.documents.length === 0 && !hasMaxError) {
			state.power.documents = state.power.documents.concat(documentsFiles);
		}

		if (state.files != null && state.files.length < 1 && state.initialFiles.length < 1 && !hasMaxError) {
			state.files = state.files.concat(file);
			this.setState(state);
		}
		this.setState({ hasMaxError });
	};

	public readonly handleDeselect = (file: File) => {
		let state = { ...this.state };
		let fileIndex = state.files.findIndex((item) => item === file);
		state.files.splice(fileIndex, 1);

		let documentIndex = state.power.documents.findIndex((item) => item.fileName === file.name);
		state.power.documents.splice(documentIndex, 1);
		
		this.setState(state);
	};

	public readonly handleDeleteFileSp = (file: DocumentFile): void => {
		let state = { ...this.state };
		let fileIndex = state.initialFiles.findIndex((item) => item === file);
		state.initialFiles.splice(fileIndex, 1);
		state.trazasDocumentos.push(`Se ha quitado el documento con el nombre: ${file.fileName}`);
		this.setState(state);
	};

	private readonly getPowerSubjects = (powerRevocationFilter: PowerRevocationFilter) => {
		let filter = { ...this.state.powerRevocationFilter };
		filter = powerRevocationFilter;

		if ((filter.personId > 0 ||filter.legalCompanyId > 0) && filter.companyId > 0) {
			this.props.getPowerSubjects(filter);
		}		
		this.setState({ powerRevocationFilter: filter });
	};
}
